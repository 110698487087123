@import './mixins.scss';

// Since css variables cannot be used in media queries, we maintain these few sass variables
$breakpoint-lg-margin: 80px;
$breakpoint-lg-size: 1920px;
$breakpoint-md-margin: 48px;
$breakpoint-md-size: 1120px;
$breakpoint-sm-margin: 24px;
$breakpoint-sm-size: 760px;
$breakpoint-xs-margin: 16px;
$breakpoint-xs-size: 360px;
$grid-page-max-width: 1280px;

@mixin page-width-xs {
  @include maxWidth($breakpoint-xs-size) {
    @content;
  }
}

@mixin page-width-sm-down {
  @include maxWidth($breakpoint-sm-size) {
    @content;
  }
}

@mixin page-width-sm-up {
  @include minWidth(calc($breakpoint-xs-size + 0.05px)) {
    @content;
  }
}

@mixin page-width-md-down {
  @include maxWidth($breakpoint-md-size) {
    @content;
  }
}

@mixin page-width-md-up {
  @include minWidth(calc($breakpoint-sm-size + 0.05px)) {
    @content;
  }
}

@mixin page-width-lg-down {
  @include maxWidth($breakpoint-lg-size) {
    @content;
  }
}

@mixin page-width-lg-up {
  @include minWidth(calc($breakpoint-md-size + 0.05px)) {
    @content;
  }
}

@mixin page-width-xl {
  @include minWidth(calc($breakpoint-lg-size + 0.05px)) {
    @content;
  }
}

@mixin responsive-page-margin {
  @include page-width-xs {
    margin-left: var(--breakpoint-xs-margin);
    margin-right: var(--breakpoint-xs-margin);
  }
  @include page-width-sm-up {
    margin-left: var(--breakpoint-sm-margin);
    margin-right: var(--breakpoint-sm-margin);
  }
  @include page-width-md-up {
    margin-left: var(--breakpoint-md-margin);
    margin-right: var(--breakpoint-md-margin);
  }
  @include page-width-lg-up {
    margin-left: var(--breakpoint-lg-margin);
    margin-right: var(--breakpoint-lg-margin);
  }
}

@mixin responsive-page-padding {
  @include page-width-xs {
    padding-left: var(--breakpoint-xs-margin);
    padding-right: var(--breakpoint-xs-margin);
  }
  @include page-width-sm-up {
    padding-left: var(--breakpoint-sm-margin);
    padding-right: var(--breakpoint-sm-margin);
  }
  @include page-width-md-up {
    padding-left: var(--breakpoint-md-margin);
    padding-right: var(--breakpoint-md-margin);
  }
  @include page-width-lg-up {
    padding-left: var(--breakpoint-lg-margin);
    padding-right: var(--breakpoint-lg-margin);
  }
}
