@import "styles/core.scss";
@mixin focus-outline {
  border-radius: var(--borders-weight-lg);
  outline: none;

  &:focus-visible {
    box-shadow: 0 0 0 var(--borders-weight-lg) var(--color-border-focus-default);
  }
}

.header {
  @include responsive-page-padding;
  @include flex-row;
  align-items: center;
  position: relative;
  z-index: var(--z-index-dropdown);
  width: 100%;
  height: var(--size-header-height-desktop);
  background-color: var(--color-background-primary);
  color: var(--color-text-primary);

  &.bottomBorder {
    border-bottom: var(--borders-weight-sm) solid var(--color-border-separator);
  }

  &.edgeToEdge {
    @include page-width-sm-up {
      padding-left: var(--breakpoint-sm-margin);
      padding-right: var(--breakpoint-sm-margin);
    }
  }

  &.transparent {
    background-color: transparent;
  }

  &.transparent-inverted {
    background-color: transparent;
    color: var(--color-text-primary-inverse);

    .dropdownAnchor {
      @include hover {
        color: currentColor;
      }
    }
  }
}

.skipToMainContentButton.skipToMainContentButton {
  position: absolute;
  transition: none;
  left: -1000px;
  opacity: 0;
  z-index: 2; // Show above search box

  &:focus {
    left: var(--space-200);
    opacity: 1;
  }
}

// Hide the button if there is no main-content id to jump to
:root:not(:has([id='main-content'])) {
  .skipToMainContentButton.skipToMainContentButton {
    display: none;
  }
}

.nav,
.left,
.right {
  @include flex-row;
  align-items: center;
  gap: 32px;
}

.nav {
  flex: 1;
  justify-content: space-between;

  @include page-width-sm-down {
    gap: var(--space-200);
  }
}

.focusContainer {
  * {
    &:focus {
      outline: none;
    }
  }
}

.logoLink {
  @include focus-outline;
  display: flex;
  align-items: center;
  justify-content: center;

  // Use padding and negative margin to give an offset box-shadow outline without taking up extra space
  padding: var(--space-50);
  margin: calc(-1 * var(--space-50));
}

.searchBox {
  flex: 1;
}

.dropdowns {
  @include flex-row;
  align-items: center;
  gap: var(--space-50);
}

.dropdownAnchor {
  @include removeDefaultButtonStyles;
  @include focus-outline;
  @include text-100-bold;
  color: currentColor;
  @include flex-row;
  align-items: center;
  padding: var(--space-50) var(--space-100);
  cursor: pointer;

  @include hover {
    color: var(--color-text-primary-hover);
  }
}

// triple selector needed to override default styling
.dropdownMenu.dropdownMenu.dropdownMenu {
  min-width: 220px;
}

// double selector needed to override default styling
.plusBackground.plusBackground {
  background-color: var(--color-background-plus);
}

// double selector needed to override default styling
.mobileIconButton.mobileIconButton {
  height: 32px;
  width: 32px;
  color: currentColor;
}

.mobileDrawerBackground {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--color-scrim-light);
  z-index: 1000;

  &:not(.isVisible) {
    opacity: 0;

    .mobileDrawer {
      transform: translateX(100dvw);
    }
  }

  &.isVisible {
    opacity: 1;

    .mobileDrawer {
      transform: translateX(0);
    }
  }

  &.isAnimating {
    animation: fade-in var(--duration-short) var(--timing-ease-out) forwards;

    .mobileDrawer {
      animation: slide-in var(--duration-short) var(--timing-ease-out) forwards;
    }

    &.isVisible {
      animation-direction: reverse;

      .mobileDrawer {
        animation-direction: reverse;
      }
    }
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes slide-in {
  0% {
    transform: translateX(100dvw);
  }
  100% {
    transform: translateX(0);
  }
}

.mobileDrawer {
  @include responsive-page-padding;
  background-color: var(--color-background-primary);
  color: currentColor;
  height: 100dvh;
  width: 100dvw;
  position: absolute;
  left: 0;
  top: 0;
  overflow: auto;

  @include flex-column;
  justify-content: space-between;
  gap: var(--space-200);
}

.drawerHeader {
  @include flex-row;
  align-items: center;
  justify-content: space-between;
  height: var(--size-header-height-mobile);
  width: 100%;
  position: sticky;
  top: 0;
  background-color: var(--color-background-primary);
  z-index: 1;
}

.drawerCTAs {
  @include flex-column;
  gap: var(--space-100);
  width: 100%;
  margin-bottom: var(--space-300);
}

.accordionContents {
  @include flex-column;
  gap: 12px;
  padding-bottom: var(--space-200);
}

// double selector needed to override default styling
.mobileMenuItem.mobileMenuItem {
  &:not(.plusBackground) {
    padding: 0;
  }

  &:focus-visible {
    box-shadow: none;
    background-color: var(--color-background-primary-hover);
  }
}

.accordionNoBorder {
  display: none;
}
